export const getAllRolesAndPrivileges = async () => {
    /*
    Returns a dictionary containing all roles and privileges for each role that we have in the system.  This is for rendering the grid / matrix
    */

    /*
    return {
        'task_editor': [
            'task.create',
            'task.view',
            'task.list',
            'task.update',
            'task.delete',
            'job.execute',
            'job.stop',
            'job.view_result',
            'job.delete_result'
        ],
        'task_viewer': [
            'task.view'
        ],
        'job_executor': [
            'job.execute',
            'job.stop',
            'job.view_result',
            'job.delete_result'
        ],
        'admin': [
            'job.stop',
            'job.delete_result'
        ],
        'ex1': [
        ]
    }

    */

    /*
    Do not be fool by the a simple first look at the above example.  In the above example, the 'task_editor' role also has some job related privileges, 
    whereas the ex1 role has no privileges assigned.  In other words, the result of this function includes the privileges that had been assigned to the 
    specific roles.
    */

    const nuxtApp = useNuxtApp();
    let result = {};
    result['Admin'] = await getAllPrivileges(); // The administrator has all the privileges.
    const resp = await nuxtApp.runWithContext(() => {
        return logFetchAJAX(getUrl(`/api/iam/roles`), { method: "GET" });
    });

    // const resp = await logFetchAJAX(url, { method: "GET" });
    //console.log("RESPONSE FROM /api/iam/roles:", resp);
    let all_roles = resp['roles'];
    all_roles?.forEach((obj) => {
        if (obj['path'] == 'dkroles') {
            let role = obj['name'];
            if (role.toLowerCase() != 'admin') {
                result[role] = [];
                if (obj.hasOwnProperty('permissions')) {
                    result[role] = obj['permissions'];
                }    
            }
        }
    });
    
    return result;
}
