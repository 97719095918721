export const getAllPrivileges = async () => {
  const resp = await logFetchAJAX(getUrl(`/api/iam/roles/privileges`), {
    method: "GET",
  });
  const privileges = resp['privileges'];
  // Only return strings
  let result = [];
  privileges.forEach((x) => {
    result.push(x['name'])
  })
  return result;
}
